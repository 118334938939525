import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import { NeedHelp } from '../views/dashboard/components/NeedHelp'
import EnterpriseIcon, { CautionFilledIcon } from '@enterprise-ui/icons'
export const ErrorContent = ({ error }) => {
  const [errorCont, setErrorCont] = useState(null)
  useEffect(() => {
    // Catch errors thrown by child components
    const handleErrors = (err) => {
      setErrorCont(err)
    }
    window.addEventListener('error', handleErrors)
    return () => {
      window.removeEventListener('error', handleErrors)
    }
  }, [])
  return (
    <>
      <Layout.Body
        data-testid="layoutWithErrorContent"
        includeRail
        className="layout-body-section"
      >
        <Helmet defaultTitle="LaunchPad: Error" />
        <Grid.Container>
          <Grid.Item xs={8} className="display-grid welcome-section">
            <Card
              className="hc-pa-md hc-mt-normal"
              style={{ minHeight: '90vh' }}
            >
              <h2 className="hc-fs-md hc-lh-expanded font-color-default font-weight-700 display-flex hc-mb-normal">
                <EnterpriseIcon
                  icon={CautionFilledIcon}
                  className="hc-clr-error hc-mr-dense hc-mt-min"
                />
                <span>Something went wrong</span>
              </h2>
              <div className="hc-mt-expanded">
                {error && (
                  <p className="hc-fs-sx font-weight-400 hc-mb-dense">
                    <strong>Error</strong> : {error.message}
                  </p>
                )}
                {errorCont && (
                  <p className="hc-fs-sx font-weight-400 hc-mb-dense">
                    {errorCont.message}
                  </p>
                )}
              </div>
            </Card>
          </Grid.Item>
          <Grid.Item xs={4}>
            <NeedHelp />
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
