import '@enterprise-ui/canvas-ui-css'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useFetchLov } from '../../hooks/useLov'
import useFetchOptions from '../../hooks/useFetchOptions'
import { SEARCH_TEXT } from '../searchConstants'
import { truncateString } from '../../../globalUtils/commonUtills'
import { mapValueTypeWithId } from '../../../globalUtils/searchUtills'

const CrossVendor = ({
  filterId,
  attributeId,
  dependentValue,
  dependentAttributeId,
  label,
  onFilterChange,
  placeholder,
  isFav,
  selectedValue,
  setSelectedValues,
  disabled,
}) => {
  const { refetch } = useFetchLov({
    variables: {
      attributeId,
      attributeParams: dependentAttributeId
        ? { [dependentAttributeId]: dependentValue ? [dependentValue] : [] }
        : {},
      searchText: '',
    },
    skip: true,
  })
  const { fetchedOptions, loading, setFetch, setSearchText } = useFetchOptions(
    refetch,
    attributeId,
    dependentAttributeId,
    dependentValue,
  )

  const handleUpdate = (id, value) => {
    const updatedValue = { ...value }
    if (updatedValue.label) {
      updatedValue.label = truncateString(updatedValue.label, 28)
    }
    setSelectedValues((prev) => ({
      ...prev,
      [filterId]: updatedValue,
    }))
    const newValue = value?.value
    // Type of value accepted by graphQL depends on the filterId
    const formattedValue = mapValueTypeWithId(filterId, newValue)
    const values = value
      ? [
          {
            value: formattedValue || null,
            pending: true,
            displayValue: value?.label,
          },
        ]
      : null
    onFilterChange(filterId, values)
  }
  return (
    <Grid.Item onClick={() => setFetch(true)} xs={12} style={{ width: '100%' }}>
      <Autocomplete
        id={`${filterId}${isFav}-filter`}
        key={`${filterId}${isFav}-key`}
        data-testid={`${attributeId}-filter`}
        label={label}
        options={(searchText) => {
          setSearchText(searchText)
          return fetchedOptions
        }}
        noResultsMessage={
          loading ? SEARCH_TEXT.LOV.LOADING : SEARCH_TEXT.LOV.NO_RESULTS
        }
        placeholder={placeholder ? placeholder : label}
        onUpdate={handleUpdate}
        value={selectedValue}
        disabled={disabled}
      />
    </Grid.Item>
  )
}

export default CrossVendor
