import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import {
  Button,
  Tooltip,
  Drawer,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CloudOffIcon } from '@enterprise-ui/icons'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useFetchLov } from '../../hooks/useLov'
import { PUBLISH_UNPUBLISH_MMB } from '../../../apiItemGraphql/searchActionBar'
import { refreshSearchFilters } from '../../../store/userSearchProfile'
import messageConstants from '../../../constants/message-constants'

const Unpublished = ({ selectedTcins, isSameMMBDept }) => {
  const dispatch = useDispatch()
  const toasterMessage = useToaster()
  const [isCrossOverUnPublishOpen, setIsCrossOverUnPublishOpen] =
    useState(false)
  const [publishFlag, setpublishFlag] = useState(null)
  const [reasonsValue, setReasonsValue] = useState(null)
  const [buttonText, setButtonText] = useState('Publish')
  const [updateMerchantPublishingStatus, { loading }] = useMutation(
    PUBLISH_UNPUBLISH_MMB,
  )

  useEffect(() => {
    if (publishFlag?.value === false) {
      setButtonText('UnPublish')
    } else {
      setButtonText('Publish')
      setReasonsValue(null)
    }
  }, [publishFlag])

  const {
    options: unpublishReasons,
    loading: unpublishReasonsLoading,
    error: unpublishReasonsError,
  } = useFetchLov({
    variables: {
      attributeId: 'merchantUnpublishReason',
      attributeParams: {
        merchantPublishFlag: [false],
      },
    },
    skip: !isCrossOverUnPublishOpen,
  })

  const cancelPublish = () => {
    setpublishFlag(null)
    setReasonsValue(null)
    setButtonText('Publish')
    setIsCrossOverUnPublishOpen(false)
  }

  const submitPublish = useCallback(async () => {
    let publishParams = {
      tcins: selectedTcins,
      merchantPublishFlag: !publishFlag?.value,
    }
    if (!publishFlag?.value) {
      publishParams = {
        ...publishParams,
        unpublishReason: `${reasonsValue.value} - ${reasonsValue.label}`,
      }
    }
    try {
      const result = await updateMerchantPublishingStatus({
        variables: publishParams,
      })

      result?.data?.updateMerchantPublishingStatus?.result.forEach((item) => {
        if (item.status !== '200') {
          toasterMessage({
            type: 'error',
            heading: `TCIN: ${item.tcin} | Status: ${item.status}`,
            message: item.errorMessage,
          })
        } else {
          toasterMessage({
            type: 'success',
            heading: `TCIN: ${item.tcin} | Status: ${item.status}`,
            message: messageConstants.MMB_UNPUBLISH_SUCCESS,
          })
          cancelPublish()
          dispatch(refreshSearchFilters())
        }
      })
    } catch (error) {
      toasterMessage({
        type: 'error',
        heading: 'Publish/UnPublish Creation Failed',
        message: messageConstants.MMB_UNPUBLISH_ERROR,
      })
    }
  }, [
    publishFlag?.value,
    updateMerchantPublishingStatus,
    reasonsValue?.label,
    reasonsValue?.value,
    selectedTcins,
    toasterMessage,
    dispatch,
  ])
  let disabledPublishButton = false
  if (publishFlag?.value || reasonsValue?.value) {
    disabledPublishButton = true
  }
  return (
    <>
      <Tooltip content="Unpublished" location="top">
        <Button
          type="ghost"
          className="button-bg-hover-transparent"
          onClick={() => setIsCrossOverUnPublishOpen(true)}
          data-testid="Unpublished"
          disabled={!isSameMMBDept}
        >
          <EnterpriseIcon
            icon={CloudOffIcon}
            size="sm"
            className={
              !isSameMMBDept ? 'hc-clr-grey03' : 'hc-clr-contrast-weak'
            }
          />
        </Button>
      </Tooltip>
      <Drawer
        isVisible={isCrossOverUnPublishOpen}
        headingText="Un/Publish MMB Web-only Items"
        onRequestClose={() => setIsCrossOverUnPublishOpen(false)}
        spacing="expanded"
        xs={3}
        className="crossover-sidecart sidecart-search"
        data-testid="unpublish-drawer"
      >
        <p>{selectedTcins.length} Items</p>

        <div className="autocomplete-load-section hc-mt-expanded">
          <Autocomplete
            id="publish-reason-id"
            options={[
              {
                id: 'yes',
                value: true,
                label: 'Yes',
              },
              {
                id: 'no',
                value: false,
                label: 'No',
              },
            ]}
            value={publishFlag}
            onUpdate={(id, value) => setpublishFlag(value)}
            placeholder="Merchant Publish Flag"
            data-testid="merchant-publish-flag-selection"
          />
        </div>

        {publishFlag?.value === false && (
          <div className="autocomplete-load-section hc-mt-expanded">
            <Autocomplete
              id="publish-reason-id"
              options={unpublishReasons}
              value={reasonsValue}
              onUpdate={(id, value) => setReasonsValue(value)}
              placeholder="Merchant Unpublish Reason"
              data-testid="merchant-publish-reason-selection"
            />
            {unpublishReasonsLoading && <span>Loading...</span>}
            {unpublishReasonsError && <p>Failed to Load Unpublish Reasons</p>}
          </div>
        )}

        <div className="mmb-action-bar hc-mt-xl">
          <Button
            type="secondary"
            className="hc-mr-expanded"
            onClick={cancelPublish}
            data-testid="cancel-unpublish"
          >
            Cancel
          </Button>
          <Button
            className="crossover-submit"
            type="primary"
            onClick={submitPublish}
            disabled={!disabledPublishButton}
            isLoading={loading}
            data-testid="submit-unpublish"
          >
            {buttonText}
          </Button>
        </div>
      </Drawer>
    </>
  )
}

export default Unpublished
