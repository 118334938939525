import { useMutation } from '@apollo/client'
import { setUserSessionMutation } from '../../apiItemGraphql/userSession'
import { useSelector } from 'react-redux'

export const useSearchSession = () => {
  let vendorValues = []
  const appliedFilters = useSelector((state) => {
    const { userSearchProfile } = state
    return userSearchProfile.savedSearch.activeSavedFilters
  }, [])
  const [setUserSearchSession] = useMutation(setUserSessionMutation())
  const updateSearchDatabase = () => {
    const vendor = appliedFilters.filter((item) => item.id === 'vendor')
    if (vendor.length >= 1) {
      vendorValues = vendor[0].values.map((v) => ({
        gmsVendorId: v.value,
        vendorName: v.displayValue.replace(`${v.value} - `, ''),
        displayName: v.displayValue,
      }))
    }
    if (vendorValues) {
      setUserSearchSession({
        variables: {
          session: {
            itemSearchAppliedFilters: {
              filters: {
                vendor: {
                  id: 'vendor',
                  values: vendorValues,
                },
              },
            },
          },
        },
      })
    }
  }
  return {
    updateSearchDatabase,
  }
}
