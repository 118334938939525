import { Routes, Route, useLocation } from 'react-router-dom'
import { ProtectedElement } from '@praxis/component-auth'
import { ErrorBoundary } from '@praxis/component-logging'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { ErrorContent } from './ErrorContent'
import { SearchHome } from '../views/searchGrid/'
import { DashboardPage } from '../views/dashboard'
import { Unauthorized } from '../views/common/Unauthorized'
import { PageNotFound } from '../views/common/PageNotFound'
import { AuthLogin } from '../views/common/AuthLogin'
import { Notify } from '../views/common/Notification'

export const MainRouter = (props) => {
  const location = useLocation()
  const { getUserProfile } = props
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <ToastProvider location="top">
        <Routes>
          {getUserProfile?.roles?.length > 0 ? (
            <>
              <Route
                path="/"
                element={
                  <ProtectedElement>
                    <DashboardPage />
                  </ProtectedElement>
                }
                unauthorizedRoute={'/unauthorized'}
              />
              {['/search', '/item-search/:path'].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <ProtectedElement>
                      <SearchHome />
                    </ProtectedElement>
                  }
                  unauthorizedRoute={'/unauthorized'}
                />
              ))}
            </>
          ) : (
            <Route path="/" element={<Unauthorized />} />
          )}
          <Route path="/auth/login" element={<AuthLogin />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Notify />
      </ToastProvider>
    </ErrorBoundary>
  )
}
