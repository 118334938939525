import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { parseJwt } from '../../../globalUtils/commonUtills'
import { getUserBlockerCounts } from '../../../apiItemGraphql/userSearch'
import { setBlockerFilters } from '../../../store/userSearchProfile'
import { useFirefly } from '../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../constants/search'
export const useSearchStickersContent = (props) => {
  const { handleTrackCustomEvent } = useFirefly()
  const dispatch = useDispatch()
  const { blocker } = props
  const [output, setOutput] = useState([])
  const [filtersVariables, setFiltersVariables] = useState({})

  const userInfo = useSelector((state) => {
    const { userProfile } = state
    const token = parseJwt(userProfile.token)
    return token?.user_info
  }, [])

  const userSelectedSticker = useSelector((state) => {
    const { userSearchProfile } = state
    const { filters } = userSearchProfile
    return filters?.stickerTypeId
  }, [])
  // const { filters } = useSelector((state) => state.userSearchProfile)
  const { filters, savedSearch } = useSelector(
    (state) => state.userSearchProfile,
  )

  useEffect(() => {
    const cleanedData = savedSearch?.activeSavedFilters.filter(
      (item) => !Array.isArray(item) || item.length > 0,
    )
    const withOutSticker = cleanedData.filter((data) => data.id !== 'sticker')

    const variables = {
      blockerType: `${blocker}-${userInfo}`,
      searchText: filters.searchText,
      filters: withOutSticker,
    }
    setFiltersVariables(variables)
  }, [filters, savedSearch, blocker, userInfo])

  const { loading, data, error } = useQuery(getUserBlockerCounts(), {
    skip: userInfo === undefined || isEmpty(filtersVariables),
    variables: filtersVariables,
  })
  const getBlockerContent = (data) => {
    const totalValue = data && data['Total']?.value // Get the numerical value of 'Total'
    let stickerOutput = []

    if (data) {
      stickerOutput = Object.entries(data)?.map(([name, details]) => {
        const completedPercentage = (
          (details.value / totalValue) *
          100
        ).toFixed(2)
        const totalPercentage = (100 - completedPercentage).toFixed(2)

        return {
          progressValue: [completedPercentage, totalPercentage],
          className: `${name}`,
          name,
          value: details.value,
          tooltip: details.tooltip,
        }
      })
    }

    setOutput(stickerOutput)
  }
  const getClassname = (className) => {
    const classNameMap = {
      'Item Data Ready': 'lp-sticker-card',
      'Item Order Ready': 'lp-sticker-card',
      'Ready for Launch': 'lp-sticker-card',
      'Not Ready for Launch': 'lp-sticker-card-new',
      'Not Ready for Order': 'lp-sticker-card-new',
      'Item Data Not Ready': 'lp-sticker-card-new',
      Total: 'lp-sticker-card-total',
      default: 'lp-sticker-card-new-dotted',
    }

    return classNameMap[className] || classNameMap.default
  }
  const getsStickerClassname = (className) => {
    return className.split(/\s+/).join('_')
  }
  const handleCardClick = (className) => {
    const blockerFilter = [
      {
        id: 'sticker',
        values: [
          {
            value: getsStickerClassname(className?.name),
            displayValue: className?.name,
          },
        ],
      },
    ]
    dispatch(setBlockerFilters(blockerFilter))

    // event capture
    handleTrackCustomEvent(
      FIREFLY_EVENTS.EVENT_TYPE.FILTER_APPLIED,
      FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.BLOCKERS,
      className?.name,
    )
  }
  useEffect(() => {
    if (data) {
      getBlockerContent(data?.getBlockerCounts?.total)
    }
  }, [data])

  return {
    loading,
    data,
    error,
    output,
    userSelectedSticker,
    handleCardClick,
    getClassname,
    getsStickerClassname,
  }
}
