import * as React from 'react'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setNotification } from '../../store/userProfile'

export const Notify = () => {
  const makeToast = ToastProvider.useToaster()
  const dispatch = useDispatch()
  const history = useNavigate()

  const { show, msg, isError, redirectPath, heading, duration } = useSelector(
    (state) => state.userProfile.notification,
  )
  const type = isError ? 'error' : 'success'
  useEffect(() => {
    if (redirectPath) {
      setTimeout(() => history(redirectPath), 0)
    }
  }, [redirectPath, history])

  useEffect(() => {
    if (show) {
      makeToast({
        autoHideDuration: duration,
        type: type,
        onComplete: () => dispatch(setNotification(false, '', isError)),
        heading: heading,
        message: msg,
        links: [],
      })
    }
  }, [show, duration, type, heading, msg, makeToast, dispatch, isError])

  return null
}
export default React.memo(Notify)
