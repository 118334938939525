import { useState } from 'react'
import {
  ExpandableSection,
  Heading,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { useSearchHeader } from './useSearchHeader'
import { SearchStickersContent } from './SearchStickersContent'
import { stickerConstants } from '../../../constants/stickers'

export const SearchHeader = (props) => {
  const { setIsExpanded, isExpanded } = props
  const { activeTab, onTabSelect } = useSearchHeader()
  const searchTabs = stickerConstants
  const [totalCount, setTotalCount] = useState(0)
  return (
    <ExpandableSection
      expanded={isExpanded}
      className="sticker-panel"
      onExpand={() => {
        setIsExpanded(!isExpanded)
      }}
    >
      <Heading size={4} className="hc-fs-md">
        Item Status Overview
        {totalCount > 0 ? (
          <span> ({totalCount.toLocaleString()})</span>
        ) : (
          <span> </span>
        )}
      </Heading>
      <ExpandableSection.Content>
        <Tabs
          activeTab={activeTab}
          onTabSelect={onTabSelect}
          size="dense"
          disableAdaptive
        >
          {searchTabs.map((field, index) => (
            <Tabs.Item
              name={field.name}
              data-testid={`${field.name}-maintainer`}
              key={`${index}-filter-tabs`}
            >
              {field.label}
            </Tabs.Item>
          ))}
          {searchTabs.map((field, index) => (
            <Tabs.Content
              name={`${field.name}`}
              data-testid={`${field.name}content-maintainer`}
              key={`${index}-filter-content`}
              className="hc-pa-none"
            >
              {field.name === activeTab && (
                <SearchStickersContent
                  key={`${index}-Search-stickers-content`}
                  name={field.name}
                  blocker={field.blocker}
                  setTotalCount={setTotalCount}
                />
              )}
            </Tabs.Content>
          ))}
        </Tabs>
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
