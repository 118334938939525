export const FILTER_MODAL = {
  SAVE_EDIT_LABEL: 'Name',
  DELETE_LABEL: 'Saved Search',
  MODAL_HEADING_EDIT: 'Edit Saved Search',
  MODAL_HEADING_DELETE: 'Are you sure you want to delete?',
  MODAL_HEADING_SAVE: 'Save New Search',
  MODAL_SHOW_ON_HOME_PAGE: 'Show on Homepage Dashboard',
}
export const SEARCH_ACTIONS = {
  MAX_VALIDATE_ITEMS: 200,
  MAX_SELECTABLE: 10000,
  MAX_EDITABLE: 100,
  MAX_DELETABLE: 5,
  GRID_PAGE_SIZE: 100,
  MAX_COPY_TCIN_SIZE: 1000,
  MAX_DQ_ISSUES_SELECTABLE: 1000,
  MAX_IMAGE_SELECTABLE: 200,
  PAGINATION_PAGE_SIZE: 100,
  DOWNLOAD_RESULT: 'Download results',
  DOWNLOAD_BULK: 'Download results with MTA',
}

export const FIREFLY_EVENTS = {
  EVENT_TYPE: {
    FILTER_APPLIED: 'Filter Applied',
    GRID_ACTION: 'Search Table Actions',
    FILTER_EDITED: 'Filter Edited',
  },
  CUSTOM_INTERACTION_KEYS: {
    BLOCKERS: 'Blockers',
    QUICK_SEARCH: 'Quick Search',
    EXCEL: 'Excel Download',
    COPY_LINK: 'Copy Link',
    FAVORITE: 'Mark as Favorite',
    MAINTAIN: 'Item Maintain',
    DELETE: 'Delete Items',
    COMMENT: 'Add Comment',
  },
  CUSTOM_INTERACTION_VALUES: {
    WITHOUT_MTA: 'Without MTA',
    WITH_MTA: 'With MTA',
    DQ_ISSUE: 'DQ Issues',
  },
}

export const MMB_DEPARTMENT_MAPPING = {
  244: 12,
  246: 58,
  247: 59,
  248: 59,
}

export const DEFAULT_DOWNLOAD_COLUMNS = [
  'tcin',
  'dpci',
  'productTitle',
  'primaryBarcode',
  'vendorDescription',
  'launchPadCreatedTimestamp',
  'launchDate',
  'commitmentDate',
  'assortmentPlanName',
  'itemTypeName',
  'primaryVendorId',
  'primaryVendorName',
  'itemDataQualityIssues',
  'vendorDetails',
  'isMMBItem',
]
