import { createSlice } from '@reduxjs/toolkit'
import { isSSOSessionActive } from '../globalUtils/commonUtills'
export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    token: '',
    session: '',
    notification: {
      show: false,
      msg: '',
      isError: false,
      redirectPath: '',
    },
  },
  reducers: {
    setUserProfile: (state, action) => {
      return {
        ...state,
        token: action.payload,
      }
    },
    setUserSession: (state, action) => {
      const { userInfo } = action?.payload
      return {
        ...state,
        session: {
          lanId: userInfo?.lanId,
        },
      }
    },
    setNotification: (state, action) => {
      const { show, msg, isError, redirectPath } = action?.payload
      return {
        ...state,
        notification: {
          show: show,
          msg: msg || '',
          isError: isError || '',
          redirectPath: redirectPath || '',
        },
      }
    },
  },
})

export const { setUserProfile, setNotification, setUserSession } =
  userProfileSlice.actions

export default userProfileSlice.reducer

export const getUserSessionToken = (state) => {
  return state.userProfile.token
}
export const checkUserSession = (state, dispatch) => {
  const token = state.userProfile.session
  return isSSOSessionActive(token, dispatch)
}
