import { Card, Layout, Heading } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CautionFilledIcon } from '@enterprise-ui/icons'

export const Unauthorized = () => {
  return (
    <Layout.Body data-testid="unauthorizedLayout" className="layout-body-full">
      <Card
        className="hc-pa-normal hc-ta-center center"
        style={{ minHeight: '90vh' }}
      >
        <div className="progress-wrapper">
          <EnterpriseIcon
            icon={CautionFilledIcon}
            className="hc-clr-error"
            size="xl"
          />
        </div>
        <Heading className="hc-pl-normal hc-pb-normal hc-mt-normal">
          You are not authorized to access this application.
        </Heading>
      </Card>
    </Layout.Body>
  )
}
