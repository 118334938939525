import { useEffect, useState, useCallback } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import { Card, Tabs } from '@enterprise-ui/canvas-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { ColumnCard } from './ColumnCard'
import { useQuery } from '@apollo/client'
import { getSearchColumns } from '../../../apiItemGraphql/userProfile'
import HeaderCheckbox from '../grid/HeaderCheckbox'
import { setActiveTab } from '../../../store/searchColumns'

export const CustomStatsToolPanel = (props) => {
  const dispatch = useDispatch()
  const activeFilterTab = useSelector((state) => state.searchColumns.activeTab)
  const [defaultColumns, setDefaultColumns] = useState([])
  const [myColumns, setMyColumns] = useState([])
  const [initialColumnsLoaded, setInitialColumnsLoaded] = useState(false)
  const [initialDefaultColumns, setInitialDefaultColumns] = useState([])
  const [initialMyColumns, setInitialMyColumns] = useState([])

  const [selectAllCheckedDefault, setSelectAllCheckedDefault] = useState(false)
  const [selectAllCheckedMy, setSelectAllCheckedMy] = useState(false)

  const [selectedDefaultColumns, setSelectedDefaultColumns] = useState([])
  const [additionalDefaultColumns, setAdditionalDefaultColumns] = useState([])
  const [selectedMyColumns, setSelectedMyColumns] = useState([])
  const [additionalMyColumns, setAdditionalMyColumns] = useState([])
  const {
    savedSearch: { activeSavedFilters },
  } = useSelector((state) => state.userSearchProfile)

  const { data, refetch } = useQuery(getSearchColumns(), {
    variables: {
      filters: activeSavedFilters,
      useColumnPreference: true,
    },
  })

  useEffect(() => {
    if (!initialColumnsLoaded && props.api) {
      const allColumns = props.api.getColumnDefs()
      setInitialDefaultColumns([...allColumns])
      setDefaultColumns([...allColumns])
      setMyColumns([...allColumns.map((col) => ({ ...col }))])
      setInitialColumnsLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data && data.searchColumns && data.searchColumns.results) {
      const updatedMyColumns = data.searchColumns.results
        .map((resultColumn) => {
          const foundColumn = myColumns.find(
            (column) => column.colId === resultColumn.id,
          )
          if (foundColumn) {
            return {
              ...foundColumn,
              width: resultColumn.width,
              hide: !resultColumn.visible,
            }
          }
          return foundColumn
        })
        .filter((column) => column !== undefined)
      setInitialMyColumns(updatedMyColumns)
      setMyColumns(updatedMyColumns)
      setInitialColumnsLoaded(true) // Set columns loaded flag to true after initial load
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (activeFilterTab === 'my_columns') {
      setSelectedMyColumns(
        myColumns.filter((col) => !col.hide && col.field !== 'selection'),
      ) // Initialize selected columns
      setAdditionalMyColumns(myColumns.filter((col) => col.hide)) // Initialize additional columns
    }
  }, [
    activeFilterTab,
    myColumns,
    selectedMyColumns.length,
    additionalMyColumns.length,
  ]) // Ensure it runs once when switching to "my_columns" tab

  useEffect(() => {
    const selectionColumn = {
      colId: 'selection',
      field: 'selection',
      headerName: '',
      checkboxSelection: true,
      maxWidth: 75,
      pinned: 'left',
      headerTooltip: 'Select All | Max 10,000',
      headerComponent: HeaderCheckbox,
    }
    if (activeFilterTab === 'default_columns') {
      setDefaultColumns([...initialDefaultColumns])
      setSelectedDefaultColumns(
        initialDefaultColumns.filter(
          (col) => !col.hide && col.field !== 'selection',
        ),
      )
      setAdditionalDefaultColumns(
        initialDefaultColumns.filter((col) => col.hide),
      )
      props.api.setColumnDefs([...initialDefaultColumns])
    } else if (
      activeFilterTab === 'my_columns' &&
      myColumns.length &&
      !myColumns.find((col) => col.colId === 'selection') // Ensure the selection column is added only once
    ) {
      setMyColumns([selectionColumn, ...myColumns]) // Add the selection column only once
      props.api.setColumnDefs([selectionColumn, ...myColumns])
    } else if (activeFilterTab === 'my_columns') {
      props.api.setColumnDefs(myColumns)
    }
  }, [activeFilterTab, initialDefaultColumns, myColumns, props.api])

  const onTabSelect = (event, tab) => {
    dispatch(setActiveTab(tab.name))
    if (tab.name === 'default_columns') {
      setDefaultColumns([...initialDefaultColumns])
      setSelectedDefaultColumns(
        initialDefaultColumns.filter(
          (col) => !col.hide && col.field !== 'selection',
        ),
      )
      setAdditionalDefaultColumns(
        initialDefaultColumns.filter((col) => col.hide),
      )
      props.api.setColumnDefs([...initialDefaultColumns])
    } else if (tab.name === 'my_columns') {
      setMyColumns([...initialMyColumns])
      setSelectedMyColumns(
        initialMyColumns.filter(
          (col) => !col.hide && col.field !== 'selection',
        ),
      )
      setAdditionalMyColumns(initialMyColumns.filter((col) => col.hide))
      props.api.setColumnDefs([...initialMyColumns])
    }
  }

  const updateList = useCallback(() => {
    const allColumns = props.api.getColumnDefs()
    if (activeFilterTab === 'default_columns') {
      setDefaultColumns(allColumns)
    } else {
      setMyColumns(allColumns)
    }
  }, [props.api, activeFilterTab])

  useEffect(() => {
    props.api.addEventListener('modelUpdated', updateList)
    props.api.addEventListener('columnVisible', updateList)

    return () => {
      props.api.removeEventListener('modelUpdated', updateList)
      props.api.removeEventListener('columnVisible', updateList)
    }
  }, [props.api, updateList])

  const handleVisibilityChange = (column) => {
    const isVisible = props.columnApi
      .getColumn(column.colId || column.field)
      .isVisible()

    props.columnApi.setColumnVisible(column.colId, !isVisible)
  }

  const handleSelectAllClickDefault = async () => {
    const newSelect = !selectAllCheckedDefault
    const newColumnState = props.api.getColumnState().map((colState) => {
      const columnName = colState.colId
      if (columnName === 'selection') {
        return colState
      }
      return { ...colState, hide: !newSelect }
    })
    props.api.applyColumnState({ state: newColumnState })
    setSelectAllCheckedDefault(newSelect)
  }

  const handleSelectAllClickMy = async () => {
    const newSelect = !selectAllCheckedMy
    const newColumnState = props.api.getColumnState().map((colState) => {
      const columnName = colState.colId
      if (columnName === 'selection') {
        return colState
      }
      return { ...colState, hide: !newSelect }
    })
    props.api.applyColumnState({ state: newColumnState })
    setSelectAllCheckedMy(newSelect)
  }

  return (
    <Card>
      <p className="hc-pt-dense hc-pl-dense hc-mb-min">
        <strong>Customise Table Columns</strong>{' '}
      </p>
      <Tabs
        activeTab={activeFilterTab}
        onTabSelect={onTabSelect}
        disableAdaptive
        className="C-ButtonTab hc-ml-dense hc-pt-dense"
      >
        <Tabs.Item name="default_columns">Default</Tabs.Item>
        <Tabs.Item name="my_columns">My Columns</Tabs.Item>
        <Tabs.Content
          name="default_columns"
          data-testid={`default_columns-content`}
          className="hc-pa-xs colum-container"
        >
          {defaultColumns && (
            <ColumnCard
              columns={defaultColumns}
              myColumns={false}
              handleSelectAllClick={handleSelectAllClickDefault}
              handleVisibilityChange={handleVisibilityChange}
              selectAllChecked={selectAllCheckedDefault}
              propsApi={props.api}
              selectedColumns={selectedDefaultColumns}
              additionalColumns={additionalDefaultColumns}
              setSelectedColumns={setSelectedDefaultColumns}
              setAdditionalColumns={setAdditionalDefaultColumns}
              refetch={refetch}
            />
          )}
        </Tabs.Content>
        <Tabs.Content
          name="my_columns"
          data-testid={`my_columns-content`}
          className="hc-pa-xs colum-container"
        >
          {myColumns && (
            <ColumnCard
              columns={myColumns}
              myColumns={true}
              handleSelectAllClick={handleSelectAllClickMy}
              handleVisibilityChange={handleVisibilityChange}
              selectAllChecked={selectAllCheckedMy}
              propsApi={props.api}
              selectedColumns={selectedMyColumns}
              additionalColumns={additionalMyColumns}
              setSelectedColumns={setSelectedMyColumns}
              setAdditionalColumns={setAdditionalMyColumns}
              refetch={refetch}
            />
          )}
        </Tabs.Content>
      </Tabs>
    </Card>
  )
}
